import { ConfigStatus } from "./ConfigurationEnums";

export interface IConfiguration {
  id: string;
  configurationId: number;
  companyId: number;
  commodityId: number;
  businessTypeId: number;
  productId: number;
  riskTakerMarginId: number;
  brokerMarginId: number;
  instrumentType: string;
  productType: string;
  isActive: boolean;
  isConfigured: boolean;
}

export interface IConfigurationDisplay {
  company?: string;
  commodity?: string;
  commodityIcon?: string;
  commodityType?: string;
  margin?: string;
  instrument?: string;
  parentConfigurationId?: number;
  type?: string;
  typeIcon?: string;
}

export class Configuration implements IConfiguration, IConfigurationDisplay {
  id = "";
  configurationId = 0;
  companyId = 0;
  commodityId = 0;
  businessTypeId = 0;
  productId = 0;
  riskTakerMarginId = 0;
  brokerMarginId = 0;
  instrumentType = "";
  productType = "";
  isActive = false;
  isConfigured = false;

  company: string = "";
  commodity: string = "";
  commodityIcon: string = "";
  commodityType: string = "";
  margin: string = "";
  instrument: string = "";
  parentConfigurationId: number = 0;
  type: string = "";
  typeIcon: string = "";

  private constructor(config: IConfiguration | null, display?: IConfigurationDisplay) {
    if (config) {
      this.id = `${config.companyId}-${config.productId}`;
      this.configurationId = config.configurationId;
      this.companyId = config.companyId;
      this.commodityId = config.commodityId;
      this.businessTypeId = config.businessTypeId;
      this.productId = config.productId;
      this.riskTakerMarginId = config.riskTakerMarginId;
      this.brokerMarginId = config.brokerMarginId;
      this.instrumentType = config.instrumentType;
      this.productType = config.productType;
      this.isActive = config.isActive;
      this.isConfigured = config.isConfigured;

      this.company = display?.company ?? "";
      this.commodity = display?.commodity ?? "";
      this.commodityIcon = display?.commodityIcon ?? "";
      this.commodityType = display?.commodityType ?? "";
      this.margin = display?.margin ?? "";
      this.instrument = display?.instrument ?? "";
      this.parentConfigurationId = display?.parentConfigurationId ?? 0;
      this.type = display?.type ?? "";
      this.typeIcon = display?.typeIcon ?? "";
    }
  }

  static get EMPTY(): Configuration {
    return Configuration.create(null);
  }

  static create(obj: IConfiguration | null, opt?: IConfigurationDisplay): Configuration {
    return new Configuration(obj, opt);
  }

  public get status(): string {
    return this.configurationId === 0 ? ConfigStatus.Unassigned :
      this.isActive && this.isConfigured ? ConfigStatus.Enabled :
      this.isActive && !this.isConfigured ? ConfigStatus.Margins :
      !this.isActive ? ConfigStatus.Suspended : "";
  }

  public isEnabled = (): boolean => this.isActive && this.isConfigured;
}
