import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridCellProps, GridColumn, GridExpandChangeEvent } from "@progress/kendo-react-grid";
import {
  createDataTree,
  extendDataItem,
  mapTree,
  TreeList,
  TreeListCellProps,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
} from "@progress/kendo-react-treelist";
import { useEffect, useState } from "react";
import { Configuration, IConfiguration, IConfigurationDisplay } from "./Configuration";
import { ConfigAction, ConfigStatus } from "./ConfigurationEnums";
import { ActionDialog, StatusIcon } from ".";
import { CustomGridCell } from "../../grid";
import { getIconPath } from "../../../utilities/textUtilities";

interface IConfigurationsGridProps {
  data: Configuration[];
  childCompanyIds: number[];
  filterText: string;
  margins: any[];
  marginField: string;
  onChange: () => void;
}

export const ConfigurationsGrid = (props: IConfigurationsGridProps) => {
  const { data, filterText } = props;
  const [gridData, setGridData] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<number[]>([]);

  type DataType = {
    productId: number;
    commodity: string;
    instrument: string;
    expanded?: boolean;
    [key: string]: any;
  };

  useEffect(() => {
    if (!data || data.length === 0) return;

    // Group data by productId
    const grouped = data
      .filter((c) =>
        `${c.company} ${c.commodity} ${c.instrument} ${c.margin}`
          .toLowerCase()
          .includes(filterText.toLowerCase()),
      )
      .reduce(
        (acc, item) => {
          const key = item.productId;
          if (!acc[key]) acc[key] = { ...item, items: [], expanded: expanded.includes(key) };
          acc[key].items.push(item);
          return acc;
        },
        {} as Record<number, { commodity: string; instrument: string; expanded: boolean; items: DataType[] }>,
      );

    const sorted = Object.values(grouped).sort((a, b) => {
      return a.commodity !== b.commodity
        ? a.commodity.localeCompare(b.commodity)
        : a.instrument.localeCompare(b.instrument);
    });

    if (JSON.stringify(sorted) !== JSON.stringify(gridData)) setGridData(sorted);
  }, [data, filterText]);

  const ProductCell = (props: GridCellProps) => {
    const { dataItem } = props;
    const { commodity, commodityType, instrument, productType, type } = dataItem;

    return (
      <CustomGridCell {...props}>
        <div style={{ display: "flex", gap: 10 }}>
          <div>
            <img
              alt={commodityType}
              src={getIconPath("commodity", commodityType)}
              title={commodityType}
              style={{ width: 16, height: 16, paddingRight: 3 }}
            />
            <span>{commodity}</span>
          </div>
          <div>
            <img
              alt={type}
              src={getIconPath("instrument", productType)}
              title={type}
              style={{ width: 16, height: 16, paddingRight: 3 }}
            />
            <span>{instrument}</span>
          </div>
        </div>
      </CustomGridCell>
    );
  };

  const expandChange = (event: GridExpandChangeEvent) => {
    const { dataItem, value } = event;
    const productId: number = dataItem.productId;

    const newItems = gridData.map((item) => {
      return item.productId === productId ? { ...item, expanded: value } : item;
    });

    setExpanded(value ? [...expanded, productId] : expanded.filter((i) => i !== productId));
    setGridData(newItems);
  };

  return (
    <Grid
      data={gridData}
      detail={(gridProps) => <DetailComponent {...gridProps} {...props} />}
      expandField="expanded"
      onExpandChange={expandChange}
    >
      <GridColumn field="commodity" title="Product" cell={ProductCell} />
    </Grid>
  );
};

interface Node extends IConfiguration, IConfigurationDisplay {
  parentId?: string;
  expanded: boolean;
  children?: Node[];
  [key: string]: any;
}

const DetailComponent = (props: any) => {
  const { dataItem, childCompanyIds, margins, marginField, onChange } = props;
  const subItemsField = "children";
  const expandField = "expanded";

  const [treeData, setTreeData] = useState<any[]>([]);
  const [action, setAction] = useState("");
  const [actionItem, setActionItem] = useState<Configuration | null>(null);

  useEffect(() => {
    const data: Node[] = dataItem?.items ?? [];
    if (!data || data.length === 0) return;

    const dataCopy = data.map((item) => ({ ...item, expanded: true })); // Expand nodes by default

    dataCopy.forEach((node) => {
      const key = node.parentConfigurationId || 0;
      const parent =
        key === 0 ? null : dataCopy.find((n) => n.configurationId === node.parentConfigurationId);
      node.parentId = parent?.id;
    });

    // Build tree
    const tree: Node[] = createDataTree(
      dataCopy,
      (n) => n.id,
      (n) => n.parentId,
      subItemsField,
    );

    setTreeData(tree);
  }, [dataItem]);

  const handleAction = (action: string, item: Configuration) => {
    setAction(action);
    setActionItem(item);
  };

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    const { dataItem, value } = e;

    setTreeData(
      mapTree(treeData, subItemsField, (item) =>
        item.companyId === dataItem.companyId && item.productId === dataItem.productId
          ? extendDataItem(item, subItemsField, { expanded: !value })
          : item,
      ),
    );
  };

  const StatusCell = (props: TreeListCellProps) => {
    const { dataItem } = props;
    const config = Configuration.create(dataItem, dataItem);
    const value = config.status;

    const status =
      value === ConfigStatus.Margins && dataItem.margin
        ? ConfigStatus.ChildMargins
        : (value as ConfigStatus);

    return (
      <td style={{ textAlign: "center" }}>
        <StatusIcon status={status} />
      </td>
    );
  };

  interface ActionsProps {
    cellProps: TreeListCellProps;
    action: string;
    onActionClick: (action: string, item: Configuration) => void;
  }

  const ActionsCell = ({ cellProps, action, onActionClick }: ActionsProps) => {
    const { dataItem } = cellProps;
    const config = Configuration.create(dataItem, dataItem);
    const disabled = action.length > 0;

    return (
      <td>
        <div style={{ display: "flex" }}>
          {config.status !== ConfigStatus.Unassigned && (
            <Button
              disabled={disabled}
              icon="edit"
              size="small"
              themeColor="primary"
              fillMode="outline"
              title="Edit Margin"
              onClick={() => onActionClick(ConfigAction.Edit, config)}
            />
          )}

          {config.configurationId > 0 && childCompanyIds.includes(config.companyId) && (
            <Button
              disabled={disabled}
              icon={`${config.isActive ? "cancel" : "check"}`}
              size="small"
              themeColor="primary"
              fillMode="outline"
              title={`${config.isActive ? "Suspend" : "Activate"}`}
              onClick={() =>
                onActionClick(
                  config.isActive ? ConfigAction.Suspend : ConfigAction.Activate,
                  config,
                )
              }
            />
          )}

          {config.status === ConfigStatus.Unassigned &&
            childCompanyIds.includes(config.companyId) && (
              <Button
                disabled={disabled}
                icon="plus"
                size="small"
                themeColor="primary"
                fillMode="outline"
                title="Assign"
                onClick={() => onActionClick(ConfigAction.Assign, config)}
              />
            )}
        </div>
      </td>
    );
  };

  const ActionsCellWrapper = (props: TreeListCellProps) => (
    <ActionsCell
      cellProps={props}
      action={action}
      onActionClick={(newAction: string) => handleAction(newAction, props?.dataItem)}
    />
  );

  const onActionComplete = () => {
    console.log("action complete");
    setAction("");
    onChange();
  };

  const columns: TreeListColumnProps[] = [
    { field: "company", title: "Company", expandable: true },
    { field: "margin", title: "Margin" },
    { field: "status", title: "Status", cell: StatusCell, width: 100 },
    { field: "configurationId", title: "Actions", cell: ActionsCellWrapper, width: 150 },
  ];

  return (
    <>
      <TreeList
        style={{ maxHeight: "510px", overflow: "auto" }}
        expandField={expandField}
        subItemsField={subItemsField}
        onExpandChange={onExpandChange}
        data={treeData}
        columns={columns}
        resizable={true}
      />

      {action && actionItem && (
        <ActionDialog
          action={action}
          data={{ item: actionItem, margins, marginField }}
          onClose={() => setAction("")}
          onSave={onActionComplete}
        />
      )}
    </>
  );
};
