import { Button } from "@progress/kendo-react-buttons";
import { NumericTextBox, RadioGroup, TextArea } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { useState } from "react";
import quotesApi from "../../../api/quotes";
import { useApi } from "../../../hooks/useApi";
import { sumByProperty } from "../../../utilities/objectUtilities";
import {
  calculateTotalPriceOverride,
  calculateUnitPrice,
} from "../../../utilities/priceOverrideUtilities";
import { LoadingIndicator } from "../../LoadingIndicator";

const APPROVE = "Approve";
const REJECT = "Reject";

type Props = {
  quoteId: number;
  getQuoteDetails(): any;
  onReject(): any;
  totalVolume: number;
  unitPriceDecimals: number;
  allowOverride: boolean;
  quote: any;
};

const QuoteReviewForm = ({
  quoteId,
  quote,
  getQuoteDetails,
  onReject,
  totalVolume,
  unitPriceDecimals,
  allowOverride,
}: Props) => {
  const approveQuoteApi = useApi(quotesApi.approveQuote);

  let { submittedPremium, submittedBrokerPremium, transactionDetails } = quote;
  const unitPrice = calculateUnitPrice(submittedPremium, unitPriceDecimals, totalVolume);
  const [priceOverride, setPriceOverride] = useState<number>(unitPrice);

  const unitBrokerPrice = calculateUnitPrice(
    submittedBrokerPremium,
    unitPriceDecimals,
    totalVolume,
  );
  const [brokerPriceOverride, setBrokerPriceOverride] = useState<number>(unitBrokerPrice);

  const totalFairValuePrice = sumByProperty(transactionDetails, "fairValuePrice");

  const unitFairValuePrice = calculateUnitPrice(
    totalFairValuePrice,
    unitPriceDecimals,
    totalVolume,
  );

  const [isApproval, setIsApproval] = useState<boolean | null>(null);
  const isRejection = isApproval === false;

  const [comments, setComments] = useState<string | undefined>();

  const reviewQuote = async (isApproval: boolean) => {
    const totalPriceOverride = calculateTotalPriceOverride(
      priceOverride,
      totalVolume,
      submittedPremium,
      unitPriceDecimals,
    );
    const brokerTotalPriceOverride = calculateTotalPriceOverride(
      brokerPriceOverride,
      totalVolume,
      submittedBrokerPremium,
      unitPriceDecimals,
    );

    await approveQuoteApi.request(
      quoteId,
      isApproval,
      totalPriceOverride,
      brokerTotalPriceOverride,
      comments,
    );

    if (isApproval) {
      getQuoteDetails();
    } else {
      onReject();
    }
  };

  const isPriceOverriden: boolean = priceOverride !== null;
  const isValid = !(isRejection && !comments);
  const showButtons = isApproval !== null;
  const showCommentsInput = isRejection || (isApproval && isPriceOverriden);

  const handleClickCancel = () => {
    setIsApproval(null);
    setComments(undefined);
  };

  const handleClickComplete = () => {
    if (isApproval === null) return;
    reviewQuote(isApproval as boolean);
  };

  const handleChangeOverride = (event: any) => {
    const value = event.value;
    setPriceOverride(value);
  };

  const handleChangeBrokerOverride = (event: any) => {
    const value = event.value;
    setBrokerPriceOverride(value);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <RadioGroup
          data={[
            { value: APPROVE, label: APPROVE },
            { value: REJECT, label: REJECT },
          ]}
          value={isApproval ? APPROVE : isRejection ? REJECT : ""}
          onChange={(e: any) => setIsApproval(e.value === APPROVE)}
          layout="horizontal"
        />
      </div>
      <div style={{ display: "flex" }}>
        {isApproval && allowOverride && (
          <>
            <div className="margin-5">
              <Label>
                Broker: &nbsp; &nbsp;
                <NumericTextBox
                  value={brokerPriceOverride}
                  onChange={handleChangeBrokerOverride}
                  format={`c${unitPriceDecimals}`}
                  spinners={false}
                  width={100}
                />
              </Label>
            </div>
            <div className="margin-5">
              <Label>
                Client: &nbsp; &nbsp;
                <NumericTextBox
                  value={priceOverride}
                  onChange={handleChangeOverride}
                  format={`c${unitPriceDecimals}`}
                  width={100}
                  spinners={false}
                />
              </Label>
            </div>
          </>
        )}
      </div>

      {showCommentsInput && (
        <div className="margin-5">
          <TextArea
            value={comments}
            onChange={(e) => setComments(e.target.value as string)}
            placeholder="Comments. . ."
            size="large"
            draggable={false}
            validationMessage="Comments are required to reject"
            valid={isValid}
          />
          {!isValid && <Error>Comments are required</Error>}
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        {showButtons ? (
          <>
            <Button
              themeColor="primary"
              onClick={handleClickComplete}
              disabled={!isValid || approveQuoteApi.loading}
            >
              Confirm
            </Button>

            <Button
              themeColor="secondary"
              onClick={handleClickCancel}
              disabled={approveQuoteApi.loading}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button style={{ visibility: "hidden", width: 1 }}>&nbsp;</Button>
        )}
      </div>

      <LoadingIndicator loading={approveQuoteApi.loading} />
    </div>
  );
};

export default QuoteReviewForm;
