import { Error } from "@progress/kendo-react-labels";
import { useState } from "react";
import { MarginsSummary } from "..";
import quotesApi from "../../../api/quotes";
import { getComponents, getQuoteDetails, getStructures } from "../../../api/vanilla";
import { useFetch, useFetchWhen } from "../../../hooks/useApi";
import { useVanillaUtil } from "../../../hooks/useVanilla";
import DateUtility from "../../../utilities/dateUtilities";
import { sumByProperty } from "../../../utilities/objectUtilities";
import { SETTLEMENT_TYPES } from "../../../utilities/vanilla/vanillaData";
import { Structure } from "../../../utilities/vanilla/vanillaEnums";
import { LoadingIndicator } from "../../LoadingIndicator";
import { IntradayCurvesSlippageChart } from "../../charts";
import { HeaderTextSelector } from "../../utilities";
import { ICommodity } from "../Commodity";
import { IInstrument } from "../Instrument";
import { QuoteDetailsHeader, QuoteSummaryCard } from "../quoteDetails";
import VanillaQuoteSummary from "./VanillaQuoteSummary";
import { VanillaStepperHeader } from "./VanillaStepperHeader";

type Props = {
  quote: any;
  refetch: () => void;
  commodity: ICommodity;
  instrument: IInstrument;
  isRiskTaker: boolean;
  onReject: () => void;
};

const QUOTE_VIEW = "Quote";
const FEES_VIEW = "Fees";
const MARKETS_VIEW = "Slippage";

export const VanillaQuoteDetails = ({
  quote,
  refetch,
  commodity,
  instrument,
  isRiskTaker,
  onReject,
}: Props) => {
  const [product] = useState<any>({ instrument, commodity });
  const [selectedView, setSelectedView] = useState(QUOTE_VIEW);
  const [chartLoading, setChartLoading] = useState(false);

  const { pricingId, quoteId, quoteName, quoteDate, transactionDetails } = quote;
  const { data: quoteDetails, loading, error, request } = useFetch(getQuoteDetails, quoteId);
  const { data: structures } = useFetch(getStructures, instrument.id);
  const { data: slippage } = useFetchWhen(quotesApi.getSlippage, isRiskTaker, quoteId, pricingId);

  let startDate = quoteDetails?.quote?.startDate;
  let endDate = quoteDetails?.quote?.endDate;
  const { settlementTypeId, structureId, clientLong, prices } = quoteDetails?.quote || {};

  const { calendarMonthOffset } = quote;

  if (quoteDetails?.quote) {
    if (startDate) {
      startDate = DateUtility.addOffsetToDateString(startDate, -1 * calendarMonthOffset);
    }

    if (endDate) {
      endDate = DateUtility.addOffsetToDateString(endDate, -1 * calendarMonthOffset);
    }
  }

  const { decimals } = commodity;
  const units = commodity?.units.slice(0, -1) ?? "";
  const isSwap = structureId === Structure.Swap;

  const structure =
    structures?.structures.find((s: any) => s.structureId === structureId)?.longName ?? "";
  const direction = clientLong ? "Long" : "Short";
  const settlement = SETTLEMENT_TYPES.find((s: any) => s.value === settlementTypeId)?.text ?? "";
  const totalVolume = sumByProperty(prices, "volume");

  const { data: componentsData } = useFetchWhen(getComponents, !!structureId, structureId);

  const components = componentsData?.components || [];

  const transactionDirection = clientLong ? 1 : 2;

  const startMonth = prices ? DateUtility.parseMonth(prices[0].date) : null;
  const endMonth = prices ? DateUtility.parseMonth(prices[prices.length - 1].date) : null;

  const calculator = useVanillaUtil(
    components,
    startMonth,
    endMonth,
    prices as number[],
    transactionDirection,
  );

  const isLoading = loading || !calculator || !quoteDetails;

  const fairValue = sumByProperty(transactionDetails, "fairValuePrice");

  const monthsLabel = quote.calendarMonthOffset === 0 ? "Contract" : "Calendar";

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ flexBasis: "80%" }}>
        <LoadingIndicator loading={loading} />
        <Error>{error}</Error>

        {!loading && (
          <QuoteDetailsHeader
            quote={quote}
            commodity={commodity}
            instrumentName={instrument.name}
            isRiskTaker={isRiskTaker}
          >
            <VanillaStepperHeader
              quoteId={quoteId}
              quoteName={quoteName}
              product={product}
              startDate={startDate}
              endDate={endDate}
              totalVolume={totalVolume}
              structure={structure}
              direction={direction}
              settlement={settlement}
              monthsLabel={monthsLabel}
            />
          </QuoteDetailsHeader>
        )}

        <div style={{ marginTop: 20 }}>
          {isRiskTaker && (
            <HeaderTextSelector
              values={[QUOTE_VIEW, FEES_VIEW, MARKETS_VIEW]}
              onChange={setSelectedView}
              selected={selectedView}
            />
          )}
        </div>

        {selectedView === QUOTE_VIEW && (
          <>
            <LoadingIndicator loading={isLoading} />
            {!isLoading && (
              <QuoteSummaryCard
                quote={quote}
                onReject={onReject}
                getQuoteDetails={refetch}
                totalVolume={totalVolume}
                unitPriceDecimals={decimals}
                allowOverride={true}
              >
                <VanillaQuoteSummary
                  prices={prices}
                  quote={transactionDetails || []}
                  approvedPremium={quote.approvedPremium}
                  approvedBrokerPremium={quote.approvedBrokerPremium}
                  columns={calculator?.columns || []}
                  units={units}
                  companyRole={quote.companyRole}
                  isSwap={isSwap}
                  unitPriceDecimals={decimals}
                  calendarMonthOffset={quote.calendarMonthOffset}
                />
              </QuoteSummaryCard>
            )}
          </>
        )}

        {selectedView === FEES_VIEW && (
          <QuoteSummaryCard
            quote={quote}
            onReject={onReject}
            getQuoteDetails={refetch}
            totalVolume={totalVolume}
            hideActions={true}
          >
            <MarginsSummary
              quoteId={quoteId}
              quote={quote}
              pricingId={pricingId}
              fairValue={fairValue}
              totalVolume={totalVolume}
              unitPriceDecimals={decimals}
              units={units}
              clientLong={clientLong}
            />
          </QuoteSummaryCard>
        )}

        {selectedView === MARKETS_VIEW && (
          <>
            <LoadingIndicator loading={chartLoading} />
            <IntradayCurvesSlippageChart
              commodity={commodity}
              showTitle={true}
              quoteSlippage={slippage?.quoteSlippage}
              onLoading={setChartLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};
